/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Page_BRAND_SECTION_PROP } from 'types';

export function BrandSection({ data }: Page_BRAND_SECTION_PROP) {
  const { title, branding_icons } = data;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="brand-section">
      {title && (
        <h2 className="brand-section-title section-tagline">{title}</h2>
      )}
      <div className="brand-section-slider-wrapper">
        <Swiper
          slidesPerView={1}
          speed={1600}
          modules={[Autoplay, Pagination]}
          mousewheel
          spaceBetween={15}
          autoplay={{
            pauseOnMouseEnter: true,
          }}
          loop
          pagination={{
            el: '.swiper-custom-pagination',
            clickable: true,
          }}
        >
          {branding_icons &&
            branding_icons?.length > 0 &&
            branding_icons.map(item => {
              const imageSource = isMobile
                ? item.mobileImage
                : item.desktopImage;
              const image = imageSource?.localFile
                ? getImage(
                    imageSource.localFile.childImageSharp.gatsbyImageData
                  )
                : null;
              if (!image) return null;
              return (
                <SwiperSlide key={`${item.title}`}>
                  <div className="brand-section-slide">
                    <div className="brand-section-slide-img">
                      <GatsbyImage
                        image={image}
                        alt={item.title || 'Slide Image'}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="swiper-custom-pagination" />
      </div>
    </div>
  );
}
