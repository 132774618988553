/* eslint-disable react/function-component-definition */
import React from 'react';
import { BlogCard } from 'components';
import { BLOG_CARD } from 'types';

interface BlogListProps {
  articles: {
    node: BLOG_CARD;
  }[];
  parentUrl: string;
}

const BlogList: React.FC<BlogListProps> = ({ articles, parentUrl }) => {
  const fewArticles = articles.length < 3;

  return (
    <div className={`bloglist ${fewArticles ? 'few-articles' : ''}`}>
      {articles.length > 0 ? (
        articles.map(({ node: item }) => (
          <BlogCard key={item.slug} blogCard={item} parentUrl={parentUrl} />
        ))
      ) : (
        <div>No Blogs Found</div>
      )}
    </div>
  );
};

export { BlogList };