import React from 'react';
import { BlogList, CTALink } from 'components';
import { RELATED_ARTICLES_PROPS, ARTICLE_TAG } from 'types';

function RelatedArticles({
  currentTags,
  allArticles,
  parentUrl,
}: RELATED_ARTICLES_PROPS) {
  const relatedArticles = allArticles.filter(({ node }) => {
    const otherArticleTags = node.article_tags.map(
      (tag: ARTICLE_TAG) => tag.title
    );
    return otherArticleTags.some((tag: string) => currentTags.includes(tag));
  });

  return (
    <div className="related-articles container">
      <div className="related-articles-wrapper">
        <h3 className="related-articles-title">Related Articles</h3>
        <div className="related-articles-btn">
          <CTALink ctaLink={parentUrl} className="btn-wrapper">
            <span className="btn-wrapper-inner">Find out more</span>
            <span className="btn-wrapper-icon" />
          </CTALink>
        </div>
      </div>
      {relatedArticles.length > 0 ? (
        <BlogList articles={relatedArticles} parentUrl={parentUrl} />
      ) : (
        <div className="no-blogs-found">No Blogs Found</div>
      )}
      <div className="related-articles-btn-mbl">
        <CTALink ctaLink={parentUrl} className="btn-wrapper">
          <span className="btn-wrapper-inner">Find out more</span>
          <span className="btn-wrapper-icon" />
        </CTALink>
      </div>
    </div>
  );
}

export { RelatedArticles };
