import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import Logo from 'assets/svg/section-logo.svg';
import { REVIEWS_SECTION_PROP, REVIEWS_PROPS } from 'types';

export function Reviews({ data }: REVIEWS_SECTION_PROP) {
  const slidesPerView = data.cardLayout === 'twoColumn' ? 2 : 1;
  
  return (
    <section className="reviews">
      <div className="container">
        {data.title && (
          <div className="section-logo">
            <Logo />
            <h2 className="section-title">{data.title}</h2>

            <p className="section-tagline">{data.summary.data.summary}</p>
          </div>
        )}
        <div className="reviews-slider">
          <Swiper
            slidesPerView={slidesPerView}
            speed={1600}
            modules={[Autoplay, Pagination]}
            mousewheel
            spaceBetween={15}
            autoplay={{
              pauseOnMouseEnter: true,
            }}
            loop
            pagination={{
              el: '.swiper-review-pagination',
              clickable: true,
              type: 'bullets',
            }}
          >
            {data?.reviews?.length > 0 &&
              data.reviews.map((reviewItem: REVIEWS_PROPS) => {
                const { quote = '', title = '', role = '' } = reviewItem;

                return (
                  <SwiperSlide key={title}>
                    <div className="reviews-card">
                      {quote && <p className="reviews-quote">{quote}</p>}
                      <div className="reviews-author">
                        {title && (
                          <p className="reviews-author-name">{title}</p>
                        )}
                        {role && <p className="reviews-author-role">{role}</p>}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div className="swiper-review-pagination" />
        </div>
      </div>
    </section>
  );
}
